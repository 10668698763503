import { useRouter } from "next/router";
import { dissoc } from "ramda";
import clsx from "clsx";
import FilterPills from "@/components/shared/FilterPills";
import { FaqAccordionItem, FaqSubject } from "@/services/prepr/queries/getFaqSubjects";
import { useValidatedQueryParams } from "@/utilities/replaceInvalidQueryParams";
import { filterFaqOrServiceItems } from "@/utilities/filterFaqOrServiceItems";
import { Grid } from "../shared/Grid";
import FaqAccordion from "./FaqBlock/FaqAccordion";
import { GetDynamicContentItemProps } from "./DynamicContentBlock/types";

interface Props {
  categoryHeading: string;
  subCategoryHeading: string;
  itemsHeading: string;
  faqSubjects: FaqSubject[];
  className?: string;
}

type FaqOverviewBlockProps = GetDynamicContentItemProps<"FaqOverviewBlock">;

export const FaqOverviewBlock: React.FC<FaqOverviewBlockProps> = (props) => {
  if (!props.faq_subjects) return null;
  return (
    <FaqBlock
      categoryHeading={props.category_heading || ""}
      subCategoryHeading={props.subcategory_heading || ""}
      itemsHeading={props.items_heading || ""}
      faqSubjects={props.faq_subjects}
    />
  );
};
const FaqBlock: React.FC<Props> = ({
  categoryHeading,
  subCategoryHeading,
  itemsHeading,
  faqSubjects,
  className,
}) => {
  const router = useRouter();
  const queryParams = useValidatedQueryParams(faqSubjects);
  const {
    accordionItems,
    selectedCategory,
    categoryFilters,
    selectedSubCategory,
    subCategoryFilters,
  } = filterFaqOrServiceItems<FaqAccordionItem[]>(faqSubjects, queryParams);
  const onCategoryChange = (category?: string) => {
    router.replace(
      {
        query: category === selectedCategory ? { ...router.query } : { ...router.query, category },
      },
      undefined,
      {
        scroll: false,
        shallow: true,
      }
    );
  };
  const onSubCategoryChange = (subCategory?: string) => {
    router.replace(
      {
        pathname: router.basePath,
        query:
          subCategory === selectedSubCategory
            ? dissoc("subCategory", router.query)
            : { ...router.query, subCategory },
      },
      undefined,
      {
        scroll: false,
        shallow: true,
      }
    );
  };

  return (
    <section data-block="FaqBlock" className={clsx("relative", className)}>
      <div className="absolute left-0 top-0 h-40 w-full" />
      <Grid className="relative z-10 min-h-40 bg-white">
        <div className="tablet:col-span-8 tablet:col-start-3 col-span-full py-10">
          <FilterPills
            heading={categoryHeading}
            items={categoryFilters}
            onClick={onCategoryChange}
            selectedItem={selectedCategory}
          />
          {selectedCategory && subCategoryFilters && subCategoryFilters.length > 1 && (
            <>
              <hr className="my-10" />
              <FilterPills
                heading={subCategoryHeading}
                items={subCategoryFilters}
                onClick={onSubCategoryChange}
                selectedItem={selectedSubCategory}
              />
            </>
          )}
        </div>
        <div className="tablet:col-span-8 tablet:col-start-3 col-span-full">
          {!!accordionItems.length && (
            <h4 className="text-h3-sm tablet:text-h4 font-sans font-bold tracking-wider">
              {itemsHeading}
            </h4>
          )}
          <FaqAccordion items={accordionItems} className="tablet:mt-11 mt-8" />
        </div>
      </Grid>
    </section>
  );
};

export default FaqBlock;
